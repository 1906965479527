import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import '../scss/home.scss';

$(() => {
    homeCarousels();
});

function homeCarousels(){

    $('.home__banner--carousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        responsive: {
            0: {
               items: 1
            }
        } 
    });

    $('.home__products--carousel').owlCarousel({
        loop: false,
        margin: 30,
        nav: true,
        dots: false,
        responsive:{
            0:{
               items:1
            },
            768:{
                items: 3
            },
            992:{
                items: 5
            }
        },
        navText: [
            '<svg width="27.088" height="90.738" viewBox="0 0 27.088 90.738"><path id="Caminho_2" data-name="Caminho 2" d="M1715.4,2005.84l-23.04,41.95,23.04,46.944" transform="translate(-1690.108 -2004.877)" fill="none" stroke="#c4ab90" stroke-width="4"/></svg>',
            '<svg width="27.088" height="90.738" viewBox="0 0 27.088 90.738"><path id="Caminho_1" data-name="Caminho 1" d="M1692.361,2005.84l23.04,41.95-23.04,46.944" transform="translate(-1690.566 -2004.877)" fill="none" stroke="#c4ab90" stroke-width="4"/></svg>'
        ] 
    });

    $('.home__about--carousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        responsive: {
            0: {
               items: 1
            }
        } 
    });
    
}